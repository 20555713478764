document.addEventListener('DOMContentLoaded', () => {

    function setHelp(input, message) {
        let ctrl = input.closest('.control');
        if(ctrl) {
            let help = ctrl.querySelector('.help');
            if(help) {
                help.textContent = message;
            }
        }
    }

    function clearDangerAfterEdit(input) {
        input.addEventListener('input', () => {
            input.classList.remove('is-danger');
            setHelp(input, '');
        });
    }

    document.querySelectorAll('input').forEach((input) => {
        input.addEventListener('invalid', () => {
            input.classList.add('is-danger');
            setHelp(input, input.validationMessage);
            clearDangerAfterEdit(input);
        });
    });

    document.querySelectorAll('form').forEach((form) => {
        form.addEventListener('submit', (event) => {
            if(!form.checkValidity()) {
                event.preventDefault();
            }
        });
    });

});
